import closeIcon from '../assets/images/close-icon.svg';
// import lukymoonLogo from '../assets/images/lukymoon-logo.png';
import settingsIcon from '../assets/images/settings-icon.svg';
import supportIcon from '../assets/images/support-icon.svg';
import linkedinIcon from '../assets/images/linkedin-icon.svg';
import fbIcon from '../assets/images/facebook-icon.svg';
import checkIconDark from '../assets/images/check-icon-dark.svg';
import checkIconWhite from '../assets/images/check-icon-white.svg';
import signLine from '../assets/images/sign-line.svg';
import googleIcon from '../assets/images/google-icon.svg';
import microsoftIcon from '../assets/images/microsoft-icon.svg';
import editIcon from '../assets/images/edit-icon.svg';
import blackPlusIcon from '../assets/images/black-plus-icon.svg';
import jobsIcon from '../assets/images/jobs-icon.svg';
import profileIcon from '../assets/images/profile-icon.svg';
import homeIcon from '../assets/images/home-icon.svg';
import teamsIcon from '../assets/images/teams-icon.svg';
import searchIcon from '../assets/images/search-icon.svg';
import filterIcon from '../assets/images/filter-icon.svg';
import favoriteIcon from '../assets/images/favorite-icon.svg';
import favoriteFillIcon from '../assets/images/favorite-fill.svg';
import compareIcon from '../assets/images/compare-icon.svg';
import arrowBackIcon from '../assets/images/arrow-back-icon.svg';
import greyCheckIcon from '../assets/images/grey-check-icon.svg';
import blueCheckIcon from '../assets/images/blue-check-icon.svg';
import addIcon from '../assets/images/add-icon.svg';
import workIcon from '../assets/images/work-icon.svg';
import calendarIcon from '../assets/images/calendar-icon.svg';
import accordionCloseIcon from '../assets/images/arrow-circle-down.svg';
import certificateIcon from '../assets/images/certificate-icon.svg';
import starIcon from '../assets/images/grade.svg';
import bgDelIcon from '../assets/images/bg-del-icon.svg';
import bgPlusIcon from '../assets/images/bg-plus-icon.svg';
import filtericon from '../assets/images/filter_icon.svg';
import bgEditIcon from '../assets/images/bg-edit-icon.svg';
import menuicon from '../assets/images/menu_icon.svg';
import dotIcon from '../assets/images/dot_icon.svg';
import messageIcon from '../assets/images/message-icon.svg';
import exportIcon from '../assets/images/export-icon.svg';
import optionIcon from '../assets/images/option-icon.svg';
import backArrow from '../assets/images/servicesArrow.svg';
import profileAvatar from '../assets/images/profile-avatar.svg'
import starImage from '../assets/images/5star.svg'
import notificationArrow from '../assets/images/notification-arrow.svg'
import sendIcon from '../assets/images/send-icon.svg';
import fileIcon from '../assets/images/send-icon.svg';
import markReadIcon from '../assets/images/mark-read-icon.svg';
import deleteIcon from '../assets/images/delete-icon.svg';
import userIcon from '../assets/images/user-icon.png'

export const Images = {
  closeIcon,
  // lukymoonLogo,
  settingsIcon,
  supportIcon,
  linkedinIcon,
  fbIcon,
  checkIconDark,
  signLine,
  googleIcon,
  microsoftIcon,
  editIcon,
  deleteIcon,
  blackPlusIcon,
  jobsIcon,
  profileIcon,
  homeIcon,
  teamsIcon,
  searchIcon,
  filterIcon,
  favoriteIcon,
  compareIcon,
  favoriteFillIcon,
  arrowBackIcon,
  greyCheckIcon,
  blueCheckIcon,
  addIcon,
  workIcon,
  calendarIcon,
  accordionCloseIcon,
  certificateIcon,
  starIcon,
  bgDelIcon,
  bgPlusIcon,
  bgEditIcon,
  filtericon,
  menuicon,
  dotIcon,
  messageIcon,
  exportIcon,
  optionIcon,
  backArrow,
  profileAvatar,
  starImage,
  notificationArrow,
  sendIcon,
  fileIcon,
  markReadIcon,
  checkIconWhite,
  userIcon
};
