// GET ONGOING BOOKINGS
export const GET_ONGOING_BOOKINGS_REQUEST = "GET_ONGOING_BOOKINGS_REQUEST"
export const GET_ONGOING_BOOKINGS_SUCCESS = "GET_ONGOING_BOOKINGS_SUCCESS"
export const GET_ONGOING_BOOKINGS_FAILURE = "GET_ONGOING_BOOKINGS_FAILURE"

// GET UPCOMING BOOKINGS
export const GET_UPCOMING_BOOKINGS_REQUEST = "GET_UPCOMING_BOOKINGS_REQUEST"
export const GET_UPCOMING_BOOKINGS_SUCCESS = "GET_UPCOMING_BOOKINGS_SUCCESS"
export const GET_UPCOMING_BOOKINGS_FAILURE = "GET_UPCOMING_BOOKINGS_FAILURE"

// GET ONGOING BOOKINGS
export const GET_COMPLETED_BOOKINGS_REQUEST = "GET_COMPLETED_BOOKINGS_REQUEST"
export const GET_COMPLETED_BOOKINGS_SUCCESS = "GET_COMPLETED_BOOKINGS_SUCCESS"
export const GET_COMPLETED_BOOKINGS_FAILURE = "GET_COMPLETED_BOOKINGS_FAILURE"

// Active Status State
export const SET_ACTIVE_STATUS = "SET_ACTIVE_STATUS"
