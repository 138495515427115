// POST Service type
export const POST_SERVICE_REQUEST = "POST_SERVICE_REQUEST"
export const POST_SERVICE_SUCCESS = "POST_SERVICE_SUCCESS"
export const POST_SERVICE_FAILURE = "POST_SERVICE_FAILURE"

// UPDATE Service
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST"
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS"
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE"

export const PREV_DATA = "PREV_DATA"
export const SET_SUCCESS = "SET_SUCCESS"
