// Search in residents table types ----start----
export const SEARCH_RESIDENTS_REQUEST = "SEARCH_RESIDENTS_REQUEST"
export const SEARCH_RESIDENTS_SUCCESS = "SEARCH_RESIDENTS_SUCCESS"
export const SEARCH_RESIDENTS_FAILURE = "SEARCH_RESIDENTS_FAILURE"
// Search in residents table types ----end----

// Search in designers table types ----start----
export const SEARCH_DESIGNERS_REQUEST = "SEARCH_DESIGNERS_REQUEST"
export const SEARCH_DESIGNERS_SUCCESS = "SEARCH_DESIGNERS_SUCCESS"
export const SEARCH_DESIGNERS_FAILURE = "SEARCH_DESIGNERS_FAILURE"
// Search in designers table types ----end----

// GET Bookings by status and search types ----start----
export const GET_BOOKINGS_BY_STATUS_REQUEST = "GET_BOOKINGS_BY_STATUS_REQUEST"
export const GET_BOOKINGS_BY_STATUS_SUCCESS = "GET_BOOKINGS_BY_STATUS_SUCCESS"
export const GET_BOOKINGS_BY_STATUS_FAILURE = "GET_BOOKINGS_BY_STATUS_FAILURE"
// GET Bookings by status and search types ----end----

// Search Services ----start----
export const SEARCH_SERVICES_REQUEST = "SEARCH_SERVICES_REQUEST"
export const SEARCH_SERVICES_SUCCESS = "SEARCH_SERVICES_SUCCESS"
export const SEARCH_SERVICES_FAILURE = "SEARCH_SERVICES_FAILURE"
// Search Services ----end----
