// GET Bookings
export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST"
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS"
export const GET_BOOKINGS_FAILURE = "GET_BOOKINGS_FAILURE"
// DATE
export const GET_YEAR_VALUE = "GET_YEAR_VALUE"
export const GET_MONTH_VALUE = "GET_MONTH_VALUE"
export const GET_DATE_VALUE = "GET_DATE_VALUE"

// Cancel Booking
export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST"
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS"
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE"

// GET Available Time Slots
export const GET_TIME_SLOT_REQUEST = "GET_TIME_SLOT_REQUEST"
export const GET_TIME_SLOT_SUCCESS = "GET_TIME_SLOT_SUCCESS"
export const GET_TIME_SLOT_FAILURE = "GET_TIME_SLOT_FAILURE"

// Service ID
export const SERVICE_ID = "SERVICE_ID"

// Update Time Slots of Booking Services
export const UPDATE_TIME_SLOTS_REQUEST = "UPDATE_TIME_SLOTS_REQUEST"
export const UPDATE_TIME_SLOTS_SUCCESS = "UPDATE_TIME_SLOTS_SUCCESS"
export const UPDATE_TIME_SLOTS_FAILURE = "UPDATE_TIME_SLOTS_FAILURE"

// Set Time Slots Update Success
export const SET_SLOTS_UPDATE_SUCCESS = "SET_SLOTS_UPDATE_SUCCESS"

// Set Success for Cancel Booking
export const SET_CANCEL_SUCCESS = "SET_CANCEL_SUCCESS"
