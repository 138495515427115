// GET NOTFICATIONS
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE"

// MARK READ
export const POST_MARK_READ = "POST_MARK_READ"
export const POST_MARK_READ_SUCCESS = "POST_MARK_READ_SUCCESS"
export const POST_MARK_READ_FAILURE = "POST_MARK_READ_FAILURE"

// DELETE NOTIFICATION

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE"


